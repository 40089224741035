import React from 'react'
import classnames from 'classnames'
import styles from './MiniButton.module.sass'

const MiniButton = ({ label, children, className, isSelected, ...props }) => {
  const cl = classnames({
    [className]: true,
    [styles.btn]: true,
    [styles.isSelected]: isSelected,
  })
  return (
    <button {...props} className={cl}>
      {children || label}
    </button>
  )
}

export default MiniButton
