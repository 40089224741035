import React, { useState, useEffect } from 'react'
import { withPrefix, graphql } from 'gatsby'
import Layout from '../components/Layout.js'
import Container from '../components/Container.js'
import Footer from '../components/Footer.js'
import SoundPlayer from '../components/SoundPlayer.js'
import MiniButton from '../components/MiniButton.js'
import taylVoices from '../data/availableTaylVoicesById.json'
import './index.sass'
import styles from './voices.module.sass'

const qualityFilterButtonText = {
  human: 'Group: Premium (HQ)',
  robot: 'Group: Standard',
  mixed: 'Group: All',
}

const Voices = ({
  data: {
    allFile: { edges: files },
  },
}) => {
  const [languages, setLanguages] = useState([])
  const [languageFilter, setLanguageFilter] = useState()
  const [qualityFilter, setQualityFilter] = useState('mixed')
  const [sounds, setSounds] = useState([])
  const [nowPlaying, setNowPlaying] = useState()

  useEffect(() => {
    const l = {}
    let s = files
      .map(({ node: { name, relativePath } }) => {
        const taylVoice = taylVoices[name]
        l[name.substring(0, name.indexOf('-'))] = true
        if (!taylVoice) console.warn('Missing voice', name)
        return {
          ...taylVoice,
          url: withPrefix(`/voices/${relativePath}`),
          name: getName(taylVoice),
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(({ id }) => {
        const isWavenet = id.toLowerCase().indexOf('wavenet') !== -1
        const lFilter =
          !languageFilter || id.substring(0, id.indexOf('-')) === languageFilter
        const qFilter =
          qualityFilter === 'mixed' ||
          (qualityFilter === 'human' && isWavenet) ||
          (qualityFilter === 'robot' && !isWavenet)
        return lFilter && qFilter
      })
    setLanguages(Object.keys(l).sort((a, b) => a.localeCompare(b)))
    setSounds(s)
  }, [languageFilter, qualityFilter, files])

  const handleToggle = sound => {
    if (sound.url === nowPlaying) return setNowPlaying()
    setNowPlaying(sound.url)
  }

  const handleShowAll = () => {
    setQualityFilter('mixed')
    setLanguageFilter()
  }

  const handleToggleQualityFilter = () => {
    const q =
      qualityFilter === 'human'
        ? 'robot'
        : qualityFilter === 'robot'
        ? 'mixed'
        : 'human'
    setQualityFilter(q)
  }

  const handleFinishedPlaying = () => {
    setNowPlaying()
  }

  const numSounds = sounds.length

  const Sounds = numSounds ? (
    <div className={styles.playlist}>
      <div>
        {sounds.map(sound => (
          <SoundPlayer
            key={sound.url}
            {...sound}
            isPlaying={nowPlaying === sound.url}
            onFinishedPlaying={handleFinishedPlaying}
            onClick={() => handleToggle(sound)}
          />
        ))}
      </div>
    </div>
  ) : (
    <>
      <p>We are working on adding more voices. Check back soon!</p>
      <p>
        You could also try{' '}
        <MiniButton label="this" onClick={() => setQualityFilter('mixed')} />.
      </p>
    </>
  )

  const title = sounds.length
    ? `Select from ${numSounds} voice${numSounds > 1 ? 's' : ''}...`
    : 'Nothing here... yet.'

  return (
    <Layout isHeaderFilled>
      <div className="section section--listen">
        <Container>
          <h2 className="center">Languages & Voices</h2>

          <div className="row center">
            <div className="col-xs-12">
              <MiniButton
                onClick={handleShowAll}
                label="Show all"
                isSelected={!languageFilter && qualityFilter === 'mixed'}
              />
              <MiniButton
                onClick={handleToggleQualityFilter}
                label={qualityFilterButtonText[qualityFilter]}
              />
            </div>
          </div>

          <div className="row center">
            <div className="col-xs-12">
              {languages.map(lang => (
                <MiniButton
                  key={lang}
                  onClick={() => setLanguageFilter(lang)}
                  label={lang.toUpperCase()}
                  isSelected={languageFilter === lang}
                />
              ))}
            </div>
          </div>

          <div className="row center">
            <div className="col-xs-12">
              <h3>{title}</h3>
              {Sounds}
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </Layout>
  )
}

function getName(lll) {
  if (!lll) return 'FUUUUU'
  const { id, name } = lll
  const lang = id.substring(0, 5)
  const hq = id.toLowerCase().indexOf('wavenet') !== -1 ? ' (HQ)' : ''
  return `${lang}: ${name}${hq}`
}

export const query = graphql`
  query VoicesQuery {
    allFile(filter: { sourceInstanceName: { eq: "voices" } }) {
      edges {
        node {
          name
          relativePath
        }
      }
    }
  }
`

export default Voices
