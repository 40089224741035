import React from 'react'
import classnames from 'classnames'
import Sound from './LoadableSound.js'
import styles from './AudioExamples.module.sass'

import imgPlay from '../images/headphonesIcon.svg'
import imgStop from '../images/controls-stop.svg'

const SoundPlayer = ({
  name,
  url,
  isPlaying,
  isSoundPrefetchEnabled,
  onFinishedPlaying,
  onClick,
}) => {
  const iconUrl = isPlaying ? imgStop : imgPlay

  const buttonClasses = classnames({
    [styles.playerbutton]: true,
    [styles.playing]: isPlaying,
  })

  return (
    <div>
      <Sound
        url={url}
        playStatus={isPlaying ? 'PLAYING' : 'STOPPED'}
        onFinishedPlaying={onFinishedPlaying}
      />

      <button className={buttonClasses} onClick={onClick}>
        <div className={styles.iconWrap}>
          <img src={iconUrl} alt="" />
        </div>
        <span>{name}</span>
      </button>
    </div>
  )
}

export default SoundPlayer
